import React from 'react'
import { Link } from 'gatsby'
import './callouts.scss'
import { ReactComponent as Icon1 } from "../../svg/MIM_Icon_1.svg"
import { ReactComponent as Icon2 } from "../../svg/MIM_Icon_2.svg"
import { ReactComponent as Icon3 } from "../../svg/MIM_Icon_3.svg"
import pages from '../../data/pages'

export default ({currentItem}) => {
    const services = pages[0].children
    return (
      <section className="homepage-callouts">
        {currentItem === services[0].title ? null : (
          <Link className="homepage-callout" to={services[0].path}>
            <div className="icon-wrapper">
              <Icon1></Icon1>
            </div>
            <span>
              {"custom manure"}
              <br></br>
              {"pumping & application"}
            </span>
          </Link>
        )}
        {currentItem === services[1].title ? null : (
          <Link className="homepage-callout" to={services[1].path}>
            <div className="icon-wrapper">
              <Icon2></Icon2>
            </div>
            <span>
              {"custom manure"}
              <br></br>
              {"hauling"}
            </span>
          </Link>
        )}
        {currentItem === services[2].title ? null : (
          <Link className="homepage-callout" to={services[2].path}>
            <div className="icon-wrapper">
              <Icon3></Icon3>
            </div>
            <span>
              {"custom haylage &"}
              <br></br>
              {"silage hauling"}
            </span>
          </Link>
        )}
      </section>
    )
}
